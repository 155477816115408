// ページ内アンカーリンクのスクロールエフェクト // Scrolling effect for anchor links within the page
// ヘッダーが固定の場合はheaderHightにヘッダーの高さを設定(PC/SP要設定) // Set header height to headerHight if header is fixed (PC/SP setting required)
jQuery(function($) {
    var headerHeight = 0; // ヘッダー追従でない場合 // if not header-following
	// var headerHeight = $('header').innerHeight(); // ヘッダー追従の場合 // if header following
    $('a[href^="#"]').click(function(){
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        if ( target.length > 0 ) {
            var position = target.offset().top - headerHeight;
            $("html, body").animate({scrollTop:position}, 550, "swing");
            return false;
        }
    });
})

// ページトップボタンの表示 // Display the page top button
jQuery(function($) {
	if ( $('#pageup').length > 0 ) {
		var topBtn = $('#pageup');
		topBtn.css("display", "none");
		//スクロールが100に達したらボタン表示 // display button when scroll reaches 100
		$(window).scroll(function () {
			if ($(this).scrollTop() > 100) {
				topBtn.fadeIn();
			} else {
				topBtn.fadeOut();
			}
		});
		//スクロールしてトップ // scroll to the top
		topBtn.click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 500);
			return false;
		});
	}
});


// ヘッダーの固定エフェクト // Fixed header effect
// jQuery(function($) {
// 	var $header = $('header');
// 	$(window).scroll(function() {
// 		if ($(window).scrollTop() > 50) {
// 			$header.addClass('fixed');
// 		} else {
// 			$header.removeClass('fixed');
// 		}
// 	});
// });

// アコーディオンの起動 // Activate the accordion
// jQuery(function($) {
// 	if ( $('dl.accordion dt').length > 0 ) {
// 		$("dl.accordion dt").click(function(){
// 			$(this).next("dd").slideToggle();
// 			$(this).toggleClass("open");
// 		});
// 	}
// });

// ドロワーメニュー // Drawer menu
jQuery(function($) {
    if ($('#toggle').length > 0) {
		$('#toggle').click(function () {
			$(this).toggleClass('toggle_active');
			$('#drawer_layer').fadeToggle();
			$('body').toggleClass('drawer_open');

			if ($(this).hasClass('toggle_active')) {
				$('nav').addClass('is_open');
				$('nav').removeClass('is_close');
			} else {
				$('nav').removeClass('is_open');
				$('nav').addClass('is_close');
			}
		});

		$('#drawer_layer').click(function () {
			$(this).fadeOut();
			$('#toggle').removeClass('toggle_active');
			$('body').removeClass('drawer_open');
			$('nav').removeClass('is_open');
			$('nav').addClass('is_close');
		});

		// エリア内リンク用 // For links within an area
		$('nav a').on('click', function(){
			// if (window.innerWidth <= 768) {
			if ($('#toggle:visible').length > 0) {
				$('#toggle').click();
			}
		});
	}

});